import axios from "axios";
import axiosRetry from "axios-retry";

const client = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    withCredentials: true,
    timeout: 60000
})

axiosRetry(client, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) => {
        return error.code === `timeout of ${client.defaults.timeout} exceeded`
    }
})

export default client;

client.updateAuth = (newToken) => {
    const headers = client.defaults.headers.common;

    if (!newToken) {
        return delete headers.authorization;
    }

    headers.authorization = `Bearer ${newToken}`;

    return true;
};