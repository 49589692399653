import React, { useState } from "react";
import Header from "./Header";
import "../assets/css/main.scss";
import Form from "./Form";
import Uploader from "./Uploader";
import ImportModal from "./ImportModal";
import BulkPredictModal from "./BulkPredictModal";

const App = () => {

    const [openImportModal, setOpenImportModal] = useState(false);
    const [openPredictModal, setOpenPredictModal] = useState(false);
    const [companies, setCompanies] = useState([]);

    const handleImportModalOpen = (open) => setOpenImportModal(open);
    const handlePredictModalOpen = (open) => setOpenPredictModal(open);

    return (
        <>
            <Header />
            <main>
                {openImportModal ? <ImportModal companies={companies} handleModalClose={handleImportModalOpen} /> : ""}
                {openPredictModal ? <BulkPredictModal companies={companies} handleModalClose={handlePredictModalOpen} /> : ""}
                <div className="file-operations">
                    <Uploader setCompanies={setCompanies} openModal={handleImportModalOpen} className="import" />
                    <Uploader setCompanies={setCompanies} openModal={handlePredictModalOpen} className="predict" />
                </div>
                <Form />
            </main>
        </>
    )
}

export default App;