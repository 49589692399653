import React, { useState, useEffect } from "react";
import fileDownload from 'js-file-download'
import CSVReader from "jquery-csv";
import axios from "./axios";

const BulkPredictModal = ({ companies, handleModalClose }) => {
    const [storedCompanies, setStoredCompanies] = useState([]);
    const [currentRangeOfRequests, setCurrentRangeOfRequests] = useState("");
    const [errorStatus, setErrorStatus] = useState({ error: false, line: 0, errorDetail: "" })
    const [progress, setProgress] = useState("0");

    const handleProgress = (currentPos, maxPos) => {
        // Sets percentage based on current position in loop
        const percentage = ((((currentPos + 1) * 100) / (maxPos * 100)) * 100)
        setProgress(percentage.toFixed(0));
    };

    const handleClose = () => {
        const modal = document.querySelector(".modal")
        modal.style.opacity = 0

        setTimeout(() => {
            handleModalClose(false)
            window.location.reload();
        }, 600)
    }

    const handleCSVData = async (csvData) => {
        let error = false;

        if(!csvData[0].hasOwnProperty("external_id")) return setErrorStatus({ error: true, line: 0, errorDetail: "The file format seems incorrect for bulk prediction." })

        for(let i = 0; i < csvData.length; i++) {
            const company = csvData[i];

            // current line of csv list
            const line = i+1

            if(company.name.length < 1) return setErrorStatus({ error: true, line: line, errorDetail: `Company name ${company.name} is too short.` });
            if(company.description.length < 9) return setErrorStatus({ error: true, line: line, errorDetail: `Company description ${company.description} is too short.` });

            await axios.post("/predict", {
                name: company.name,
                description: company.description,
                force_prediction: true
            })
            .then((res) => {
                if(res.status === 200) {
                    // If raw_prediction is not returned add stored record with 100% confidence
                    if(!res.data.raw_prediction) {
                        setStoredCompanies(oldCompanies => [
                            ...oldCompanies,
                            {
                                "external_id": company.external_id,
                                "id": res.data.stored_record.id,
                                "name": res.data.stored_record.name,
                                "description": res.data.stored_record.description,
                                "1st_sector": res.data.stored_record.sector,
                                "1st_sector_confidence": "100.00%",
                                "2nd_sector": "",
                                "2nd_sector_confidence": "",
                                "1st_industry_level_1": res.data.stored_record.pitchbook_level_1,
                                "1st_industry_level_2": res.data.stored_record.pitchbook_level_2,
                                "1st_industry_confidence": "100.00%",
                                "2nd_industry_level_1": "",
                                "2nd_industry_level_2": "",
                                "2nd_industry_confidence": "",
                                "3rd_industry_level_1": "",
                                "3rd_industry_level_2": "",
                                "3rd_industry_confidence": "",
                                "1st_tertiary_tag": res.data.stored_record.tertiary_tag,
                                "1st_tertiary_tag_confidence": "100.00%",
                                "2nd_tertiary_tag": "",
                                "2nd_tertiary_tag_confidence": "",
                                "validation_status": res.data.stored_record.verified
                            }
                        ])
                    } else {
                        // If raw_prediction is returned add new prediction record
                        const rawPrediction = res.data.raw_prediction
                        const sectorInfo = Object.entries(rawPrediction["Sector code"])
                        const industry1Info = rawPrediction["Pitchbook Industry level 1 code"]
                        const industry2Info = Object.entries(rawPrediction["Pitchbook Industry level 2 code"])
                        const tertiaryTagInfo = Object.entries(rawPrediction["Tertiary tag"]);
    
                        let sectors = []
    
                        sectorInfo.forEach((s, i) => {
                            if(i < 2) {
                                sectors = [...sectors, { sector: s[0], percent: (s[1] * 100).toFixed(2)}]
                            }
                        })
    
                        let industries = []
    
                        industry1Info.forEach((ind, i) => {
                            if(i < 3) {
                                industries = [
                                    ...industries, 
                                    { industry1: ind, industry2: industry2Info[i][0], percent: (industry2Info[i][1] * 100).toFixed(2) }
                                ]
                            }
                        })

                        const tertiaryTags = tertiaryTagInfo?.slice(0,2).map(x => ({
                            tertiary_tag: x[0],
                            percent: (x[1] * 100).toFixed(2)
                        }))

                        setStoredCompanies(oldCompanies => [
                            ...oldCompanies,
                            {
                                "external_id": company.external_id,
                                "id": res.data.stored_record.id,
                                "name": res.data.stored_record.name,
                                "description": res.data.stored_record.description,
                                "1st_sector": sectors[0].sector,
                                "1st_sector_confidence": sectors[0].percent,
                                "2nd_sector": sectors[1].sector,
                                "2nd_sector_confidence": sectors[1].percent,
                                "1st_industry_level_1": industries[0].industry1,
                                "1st_industry_level_2": industries[0].industry2,
                                "1st_industry_confidence": industries[0].percent,
                                "2nd_industry_level_1": industries[1].industry1,
                                "2nd_industry_level_2": industries[1].industry2,
                                "2nd_industry_confidence": industries[1].percent,
                                "3rd_industry_level_1": industries[2].industry1,
                                "3rd_industry_level_2": industries[2].industry2,
                                "3rd_industry_confidence": industries[2].percent,
                                "1st_tertiary_tag": tertiaryTags[0].tertiary_tag,
                                "1st_tertiary_tag_confidence": tertiaryTags[0].percent,
                                "2nd_tertiary_tag": tertiaryTags[1].tertiary_tag,
                                "2nd_tertiary_tag_confidence": tertiaryTags[1].percent,
                                "validation_status": res.data.stored_record.verified
                            }
                        ])
                    }

                }
            })
            .catch(err => {
              // Pause progress and notify which line the error was in the csv file
              if(err.response.data) {
                error = true;
                setErrorStatus({ error: true, line: line, errorDetail: company.name });
              } else {
                error = true;
                setErrorStatus({ error: true, line: line, errorDetail: "There was an error processing the request" });
              }
            })

            handleProgress(i, csvData.length);
            setCurrentRangeOfRequests(`${line} of ${csvData.length}`)

            if (error) break;
        }
    } 

    const handleModalLoad = async (data) => {
        const modal = document.querySelector(".modal");

        modal.style.opacity = 1;

        await handleCSVData(data);
    }

    useEffect(() => {
        if(progress ===  "0") (async () => await handleModalLoad(companies))();
    }, [])

    useEffect(() => {
        if(parseInt(progress) === 100 && storedCompanies.length > 0) {
            const CSVs = CSVReader.fromObjects(storedCompanies);

            fileDownload(CSVs, `bulk_predictions_${new Date().toISOString().split('.')[0].replace(/[^\d]/gi,'')}.csv`);            
        }
    }, [progress, storedCompanies])

    return (
        <div className="modal">
            <form onSubmit={(e) => e.preventDefault()}>
                <div onClick={() => handleClose()} className="modal-close flex-center">{"\u2715"}</div>
                <progress className={errorStatus.error ? "error" : ""} value={progress} max="100"></progress>
                <div className={errorStatus.error ? "error progress-percent" : "progress-percent"}>{progress}% Complete</div>
                <div className={errorStatus.error ? "error progress-text" : "progress-text"}>Processing line {currentRangeOfRequests}</div>
                <h4 className={errorStatus.error ? "error" : ""}>
                    {parseInt(progress) !== 100 ? "Processing CSV File" : "Processed CSV File"}
                </h4>
                <div className={errorStatus.error ? "error progress-text" : "progress-text"}>
                    {
                        errorStatus.error ? `There was an error predicting a company ${errorStatus.errorDetail} on line ${errorStatus.line}`
                            : parseInt(progress) !== 100 ? "Please wait while predictions are processing..."
                                : "All predictions have been completed."
                    }
                </div>
                <button onClick={() => handleClose()} className="main-button" disabled={parseInt(progress) !== 100} type="button">OK</button>
            </form>
        </div>
    );
};

export default BulkPredictModal;