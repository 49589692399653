/* eslint-disable no-loop-func */
import React, { useState, useEffect } from "react";
import axios from "./axios";

const ImportModal = ({ companies, handleModalClose }) => {
    const [currentRangeOfRequests, setCurrentRangeOfRequests] = useState("");
    const [errorStatus, setErrorStatus] = useState({ error: false, line: 0, errorDetail: "" })
    const [progress, setProgress] = useState("0");

    const handleProgress = (currentPos, maxPos) => {
        // Sets percentage based on current position in loop
        const percentage = ((((currentPos + 1) * 100) / (maxPos * 100)) * 100)
        setProgress(percentage.toFixed(0));
    };

    const handleClose = () => {
        const modal = document.querySelector(".modal")
        modal.style.opacity = 0

        setTimeout(() => {
            handleModalClose(false)
        }, 600)
        // cancels requests
        window.stop()
    }

    const handleCSVData = async (csvData) => {
        // maximum allowed running requests
        const maxRunningRequests = 3;
        // current running requests
        let runningRequests = 0;
        // request / response objects
        let requests = [];
        let error = false;

        if(csvData[0].hasOwnProperty("external_id")) return setErrorStatus({ error: true, line: 0, errorDetail: "The file format seems incorrect for CSV import." })

        for(let i = 0; i < csvData.length; i++) {
            const company = csvData[i];


            const request = axios.put("/import-company", {
                "id": company.id,
                "verified": company.verified === "1",
                "name": company.name,
                "description": company.description,
                "sector": company.sector,
                "pitchbook_level_2": company.pitchbook_level_2,
                "tertiary_tag": company.tertiary_tag
            })
            .then((res) => res)
            .catch(err => {
              // Pause progress and notify which line the error was in the csv file
              if(err.response.data) {
                // ignores error for already verified companies
                if(err.response.data.detail === "Already verified this ID") return;
                error = true;
                setErrorStatus({ error: true, line: i + 2, errorDetail: company.name });
              } else {
                error = true;
                setErrorStatus({ error: true, line: i + 2, errorDetail: "There was an error processing the request" });
              }
            })

            runningRequests++
            requests = [...requests, request]

            if(runningRequests === maxRunningRequests) {
                handleProgress(i, csvData.length);
                setCurrentRangeOfRequests(`${i + 2 - maxRunningRequests} - ${i + 1} of ${csvData.length}`)
                await Promise.all(requests);
                runningRequests = 0;
                requests = []
            }

            if (error) break;

            if(i+1 === csvData.length) handleProgress(i, csvData.length)
        }
    }

    const handleModalLoad = async (data) => {
        const modal = document.querySelector(".modal");

        modal.style.opacity = 1;

        await handleCSVData(data);
    }

    useEffect(() => {
        (async () => await handleModalLoad(companies))()
    }, [])

    return (
        <div className="modal">
            <form onSubmit={(e) => e.preventDefault()}>
                <div onClick={() => handleClose()} className="modal-close flex-center">{"\u2715"}</div>
                <progress className={errorStatus.error ? "error" : ""} value={progress} max="100"></progress>
                <div className={errorStatus.error ? "error progress-percent" : "progress-percent"}>{progress}% Complete</div>
                <div className={errorStatus.error ? "error progress-text" : "progress-text"}>Processing line {currentRangeOfRequests}</div>
                <h4 className={errorStatus.error ? "error" : ""}>
                    {parseInt(progress) !== 100 ? "Processing CSV File" : "Processed CSV File"}
                </h4>
                <div className={errorStatus.error ? "error progress-text" : "progress-text"}>
                    {
                        errorStatus.error ? `There was an error at line ${errorStatus.line} processing company ${errorStatus.errorDetail}`
                            : parseInt(progress) !== 100 ? "Companies are being verified and updated. Please wait for process to complete..."
                                : "Companies were verified and updated."
                    }
                </div>
                <button onClick={() => handleClose()} className="main-button" disabled={parseInt(progress) !== 100} type="button">OK</button>
            </form>
        </div>
    );
};

export default ImportModal;