import React, { useState } from "react";
import CSVReader from "jquery-csv";
import importIcon from "../assets/images/cloud-upload.svg"
import exportIcon from "../assets/images/cloud-download.svg"

const Uploader = ({ setCompanies, openModal, className }) => {

  const [fileData] = useState("");

  const handleFileSelection = (e) => {
    const fileReader = new FileReader();

    // Alert user if incorrect file type
    if(!e.target.files[0].type.includes("csv")) return alert("Not a csv file");

    fileReader.readAsText(e.target.files[0]);

    fileReader.onloadend = () => {
      
      const companyData = fileReader.result;
      const result = CSVReader.toObjects(companyData)

      // Pass value of CSVreader to parent element and open modal 
      // to start processing companies
      setCompanies(result);
      openModal(true);
    }
  }

  const isImport = className === "import";

  return (
    <div className={className}>
      <form onSubmit={(e) => e.preventDefault()}>
        <label className="secondary-button" htmlFor={className}>
          <img src={ isImport ? importIcon : exportIcon } alt="import button" />
          { isImport ? "Import CSV" : "Bulk Predict"}
        </label>
        <input
          id={className}
          type="file"
          value={fileData}
          onChange={(e) => handleFileSelection(e)}
          accept=".csv"
        />
      </form>
    </div>
  );
};

export default Uploader;