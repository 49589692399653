import React from "react";
import logo  from "../assets/images/orrick-logo.png"

const Header = () => {
    return (
        <header>
            <img className="logo" alt="logo" src={logo} />
                <div className="logo-text_container">
                    <div className="logo-text_name">
                        CLIENT INDUSTRY AND SECTOR TAGGER
                    </div>
                    <div className="logo-text_orrick">
                        @orrick
                    </div>
                </div>
        </header>
    )
}

export default Header;