import React, { useState } from "react";
import axios from "./axios"
import exclamation from "../assets/images/error-exclamation.png";
import close from "../assets/images/error-close.png";

const Form = () => {

    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [showTables, setShowTables] = useState(false);
    const [error, setError] = useState({
        description: false,
        name: false,
        request: false
    });
    const [tableData, setTableData] = useState(
        {
            sectors: [
            ],
            industries: [
            ],
            tertiaryTags: []
        }
    );

    const handleLoading = (bool) => setLoading(bool);

    const clearError = (fieldName) => {
        let newError = error;
        newError[fieldName] = false;

        setError({ ...newError, request: false })
    }

    const handleOutputErrorClose = () => {
        document.querySelector(".error-output").style.opacity = 0;
        setTimeout(() => {
            setError({ ...error, request: false })
        }, 400)
        document.querySelector(".error-output").style.opacity = 1;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const descriptionRegex = /^[\S\s]{10,}/
        const nameRegex = /^[\S\s]{3,}/
        if(!descriptionRegex.test(description) && !nameRegex.test(name)) {
            setError({
                ...error,
                description: true,
                name: true
            })
            return
        } else if(!descriptionRegex.test(description)) {
            setError({ ...error, description: true })
            return
        } else if(!nameRegex.test(name)) {
            setError({ ...error, name: true })
            return
        }

        handleLoading(true);
        setShowTables(false);

        axios.post("/predict", {
            name: name,
            description: description,
            force_prediction: true
        })
            .then(res => {
                if(res.status === 200 && res.data && res.data.raw_prediction) {
                    const rawPrediction = res.data.raw_prediction
                    const sectorInfo = Object.entries(rawPrediction["Sector code"])
                    const industry1Info = rawPrediction["Pitchbook Industry level 1 code"]
                    const industry2Info = Object.entries(rawPrediction["Pitchbook Industry level 2 code"])
                    const tertiaryTagInfo = Object.entries(rawPrediction["Tertiary tag"])
                    let sectors = []

                    sectorInfo.forEach((s, i) => {
                        if(i < 2) {
                            sectors = [...sectors, { sector: s[0], percent: (s[1] * 100).toString().substring(0, 4)}]
                        }
                    })

                    let industries = []

                    industry1Info.forEach((ind, i) => {
                        if(i < 3) {
                            industries = [
                                ...industries, 
                                { industry1: ind, industry2: industry2Info[i][0], percent: (industry2Info[i][1] * 100).toString().substring(0, 4) }
                            ]
                        }
                    })

                    const tertiaryTags = tertiaryTagInfo?.slice(0,2).map(x => ({
                        tertiary_tag: x[0],
                        percent: (x[1] * 100).toString().substring(0,4)
                    }))

                    handleLoading(false);
                    setShowTables(true);
                    setTableData({ sectors, industries, tertiaryTags })
                } else {
                    handleLoading(false);
                    setError({ ...error, request: true })
                }
            })
            .catch(err => {
                console.log(err);
                setError({ ...error, request: true })
                setTimeout(() => {
                    document.querySelector(".error-output").style.opacity = 0;
                    setTimeout(() => {
                        setError({ ...error, request: false })
                    }, 400)
                    document.querySelector(".error-output").style.opacity = 1;
                }, 30000)
                handleLoading(false);
            })
    }

    const renderSectorInfo = tableData.sectors.map((sec, i) => {
        return (
            <tr key={i}>
                <td className="bb">{sec.sector}</td>
                <td className="bb">{parseFloat(sec.percent).toFixed(1)}</td>
            </tr>
        )
    })

    const renderIndustryInfo = tableData.industries.map((industryObj, i) => {
        return (
            <tr key={i}>
                <td className="bb">{industryObj.industry1}</td>
                <td className="bb">{industryObj.industry2}</td>
                <td className="bb">{parseFloat(industryObj.percent).toFixed(1)}</td>
            </tr>
        )
    })

    const renderTertiaryTagInfo = tableData.tertiaryTags.map((tertiaryTagObj, i) => {
        return (
            <tr key={i}>
                <td className="bb">{tertiaryTagObj.tertiary_tag}</td>
                <td className="bb">{parseFloat(tertiaryTagObj.percent).toFixed(1)}</td>
            </tr>
        )
    })

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <div className="intro">
                    Enter company description and company name to request a prediction. The prediction will return Sector, Industry level 1 and Industry level 2 in the output fields that are labeled
                </div>
                <hr />
                <div className={error.description ? "input-container error-field" : "input-container"}>
                    <label>Company Description</label>
                    <textarea
                        onBlur={(e) => clearError("description")}
                        required
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <div className={error.description ? "error" : "error hidden"}>The description of company must be longer than 10 characters</div>
                </div>
                <div className={error.name ? "input-container error-field" : "input-container"}>
                    <label>Company Name</label>
                    <input
                        onBlur={() => clearError("name")}
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <div className={error.name ? "error" : "error hidden"}>The name of company must be longer than 3 characters</div>
                </div>
                { loading ? (
                    <div className="loading-ring"><div></div><div></div><div></div><div></div></div>
                ) : (
                    <button className="main-button" type="submit">
                        Make Prediction
                    </button>
                ) }
                <div className={error.request ? "error-output" : "error-output hidden"}>
                    <img alt="important" className="exclamation" src={exclamation} />
                    There was an error processing this request
                    <img alt="close" className="close" src={close} onClick={() => handleOutputErrorClose()} />
                </div>
                <div className={showTables ? "output-containers" : "hidden output-containers"}>
                    <table className="sector">
                        <thead>
                            <tr>
                                <th className="bb">Sector</th>
                                <th className="bb">%</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderSectorInfo}
                        </tbody>
                    </table>
                    <table className="industry">
                        <thead>
                            <tr>
                                <th className="bb">Industry Level 1</th>
                                <th className="bb">Industry Level 2</th>
                                <th className="bb">%</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderIndustryInfo}
                        </tbody>
                    </table>
                    <table className="tertiaryTag">
                        <thead>
                            <tr>
                                <th className="bb">Tertiary Tag</th>
                                <th className="bb">%</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTertiaryTagInfo}
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    )
}

export default Form;