import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import AuthWrapper from "./components/auth/AuthWrapper";

const root = ReactDOM.createRoot(document.querySelector("#root"))

root.render(
    <React.StrictMode>
      <AuthWrapper>
        <App />
      </AuthWrapper>
    </React.StrictMode>
)
